<script setup lang="ts">
import { useProjectsStore } from "@/stores/db/projects";
import { storeToRefs } from "pinia";
import { type IUser } from "@/stores/db/users";

useHead({
  title: "Dispatch",
});

definePageMeta({
  layout: "dashboard-fluid",
  auth: true,
});

const { success, info, warning, error, clear } = useAlertStore();

//***************************************** data

const { currentProject, userProjects, userFormsByProject } = storeToRefs(
  useProjectsStore()
);
const { fetchUserFormsByProject } = useProjectsStore();

//***************************************** methods

cs(`pages.dispatch`);
</script>
<template>
  <div>
    <!-- <v-row>
      <ClientOnly>
        <v-col
          cols="12"
          md="8"
          lg="6"
          offset-lg="3"
          offset-md="2"
        >
          <div class="d-flex justify-center flex-wrap">
            <v-btn
              v-for="form in userFormsByProject"
              :key="form.form_id"
              variant="text"
              :to="`/dispatch/${form.form_id}`"
            >
              {{ form.form_title }}
            </v-btn>
          </div>
        </v-col>
      </ClientOnly>
    </v-row> -->
    <NuxtPage />
  </div>
</template>

<style scoped></style>
